import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import RichOrText from '../Elements/RichOrText';
import StripeSection from '../Elements/StripeSection';

const HighlightSection = ({
  context: { uid },
  slice: { primary: { title } },
}) => {
  const { integrations } = useStaticQuery(graphql`
    query {
      integrations : allPrismicIntegration {
        edges {
          node {
            uid
            data {
              body {
                ... on PrismicIntegrationDataBodyHighlightentry {
                  slice_type
                  primary {
                    title
                    subject
                  }
                  items {
                    bullet_point
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    node: {
      data: {
        body: slices,
      },
    },
  } = integrations.edges.find(({ node }) => node.uid === uid);
  const entries = slices.filter(({ slice_type }) => slice_type === 'highlightentry');
  return (
    <StripeSection title={title}>
      <div className="flex flex-col items-center gap-12 w-full py-16">
        {entries.map(({ primary, items }) => (
          <div className="bg-white rounded-xl tbox-shadow w-full max-w-4xl p-8 flex flex-col gap-3 border-b-8 border-solid border-[#00ED95]">
            <h3 className="lg:text-5xl md:text-4xl sm:text-3xl text-2xl font-medium tracking-tighter">
              {primary.title}
            </h3>
            <div className="uppercase font-header text-md text-blue">
              {primary.subject}
            </div>
            <div />
            <ul className="List">
              {items.map(({ bullet_point }) => (
                <li>{bullet_point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </StripeSection>
  );
};

export default HighlightSection;

export const query = graphql`
  fragment integrationHighlightsectionSlice on PrismicIntegrationDataBodyHighlightsection {
    id
    slice_type
    primary {
      title
    }
  }
`
