import React from 'react';
import { graphql } from 'gatsby';

import StripeSection from '../Elements/StripeSection';

const TextSection = ({
  slice: {
    primary: {
      title,
      details,
    },
  },
}) => (
  <StripeSection
    className="w-full my-16"
    title={title}
    description={details}
    descriptionProps={{
      components: {
        list: ({ children }) => (
          <ul className="List">
            {children}
          </ul>
        ),
      },
    }}
  />
);

export default TextSection;

export const query = graphql`
  fragment integrationTextsectionSlice on PrismicIntegrationDataBodyTextsection {
    id
    slice_type
    primary {
      title
      details { richText }
    }
  }
`
