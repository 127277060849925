import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Hero from '../CarriersByCountry/Hero';

const HeroSection = ({
  context: { uid },
  slice: { primary },
}) => {
  const { integrations } = useStaticQuery(graphql`
    query {
      integrations : allPrismicIntegration {
        edges {
          node {
            uid
            data {
              name
              logo_dark {
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
              logo_colored {
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    node: {
      data: {
        name,
        logo_dark,
        logo_colored,
      },
    },
  } = integrations
    .edges
    .find(({ node }) => node.uid === uid);
  return (
    <Hero {...primary}>
      <img
        alt={name}
        className="max-h-[70px] max-w-[200px]"
        src={logo_colored.fluid?.srcWebp || logo_dark.fluid?.srcWebp}
        srcset={logo_colored.fluid?.srcSetWbp || logo_dark.fluid?.srcSetWbp}
      />
    </Hero>
  );
};

export default HeroSection;

export const query = graphql`
  fragment integrationHeroSlice on PrismicIntegrationDataBodyHero {
    id
    slice_type
    primary {
      heading { richText }
      sub_heading
      button_text
      button_link
      image {
        gatsbyImageData(layout: FIXED)
      }
    }
  }
`
