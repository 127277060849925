import HeroSection from './HeroSection';
import TextSection from './TextSection';
import HighlightSection from './HighlightSection';
import ContactSection from './ContactSection';
import FaqSection from './FaqSection';

export const components = {
  hero: HeroSection,
  textsection: TextSection,
  highlightsection: HighlightSection,
  contactsection: ContactSection,
  faqsection: FaqSection,
}
