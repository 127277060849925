import React from 'react';
import { graphql } from "gatsby"

import CustomerExperience from '../Elements/CustomerExperience';
import FaqSection from '../Elements/FaqSection';
import SectionGradient from '../Elements/SectionGradient';

export default (props) => (
  <>
    <div className="relative pt-32">
      <SectionGradient color="white" invert position="top" />
      <FaqSection className="bg-white" {...props} />
    </div>
      <div className="bg-gradient xl:py-32 md:py-24 py-16 text-white">
      <CustomerExperience items="clients" />
    </div>
  </>
);

export const query = graphql`
  fragment integrationFaqsectionSlice on PrismicIntegrationDataBodyFaqsection {
    id
    slice_type
    items {
      faq {
        document {
          ... on PrismicFaq {
            id
            data {
              question {
                text
                html
              }
              answer {
                text
                html
                richText
              }
            }
          }
        }
      }
    }
  }
`
