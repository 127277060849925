import React from 'react';
import { graphql } from "gatsby"

import CustomerExperience from '../Elements/CustomerExperience';

const ContactSection = ({
  slice: {
    primary: {
      heading,
    },
  },
}) => (
  <div className="xl:py-40 md:py-24 py-16 text-blue">
    <CustomerExperience
      heading={heading}
      theme="light"
      button_text="Contact us"
      button_link="/contact/"
    />
  </div>
);

export default ContactSection;

export const query = graphql`
  fragment integrationContactsectionSlice on PrismicIntegrationDataBodyContactsection {
    id
    slice_type
    primary {
      heading : title {
        text
      }
    }
  }
`
